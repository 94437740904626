import React,  { useLayoutEffect, useState }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";

import { Container, Row, Col, Button, CardDeck, Collapse } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import scrollToElement from '../helpers/scrollToElement';

import SaleProducts from "../components/SaleProduct/SaleProducts";
import SaleProductProvider from "../SaleProduct/SaleProductContext";

import { graphql } from "gatsby";

import icon1 from '../images/company/icon_1_change.svg'
import icon2 from '../images/company/icon_2_training.svg'
import icon3 from '../images/company/icon_3_online_care.svg'
import icon4 from '../images/company/icon_4_special_meal.svg'
import icon5 from '../images/company/icon_5_stats.svg'
import icon6 from '../images/company/icon_6_motivation.svg'
import icon7 from '../images/company/icon_7_straining_stuff.svg'
import icon8 from '../images/company/icon_8_shopping.svg'

import appIcon from '../images/company/app_iconx2.png';

import level1 from '../images/fast/level_1.svg';
import level2 from '../images/fast/level_2.svg';
import level3 from '../images/fast/level_3.svg';

import metamorphose1 from '../images/fast/metamorfozy-magda.jpg';
import metamorphose2 from '../images/fast/metamorfozy-aneta.jpg';
import metamorphose3 from '../images/fast/metamorfozy-elwira.jpg';

import Slider from 'react-slick';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return size;
}

const Page = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [width] = useWindowSize();

    const settingsDesktop = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 7000,
    };

    const settingsMobile = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 7000,
    };

    let settings = settingsMobile;

    if(width > 768){
        settings = settingsDesktop;
    }

    const toggle = () => {
        setIsOpen(!isOpen);    
    }

    const bgImage = convertToBgImage(data.hero.childImageSharp.gatsbyImageData) 

    return (
        <Layout page="fast" showMenu={true}>
            <BackgroundImage
                Tag="section"
                className="hero"                
                {...bgImage}
            >
                <Container> 
                    <h1 className="text-center">Szybkie i proste treningi oraz dieta</h1>
                    <h2 className="text-center">Ćwiczysz gdzie i kiedy chcesz dzięki aplikacji</h2>
                    <Button onClick={() =>
                        scrollToElement(
                            document.getElementById('buy')
                        )
                    }>
                        Odbierz teraz rabat <strong>-30%</strong>
                    </Button>
                </Container>
            </BackgroundImage>
    
            <section className="fast1">
                <Container>
                    <Row>
                        <Col md="5">
                            <GatsbyImage image={data.app1.childImageSharp.gatsbyImageData} />
                        </Col>
                        <Col md="7">
                            <ul>
                                <li>Trening i dieta są dopasowane indywidualnie</li>
                                <li>Trening już od 15 minut dziennie</li>
                                <li>Pierwsze efekty czujesz po 7 dniach</li>
                                <li>Taniej niż karnet na siłownię</li>
                                <li>Ponad 70 planów treningowych</li>
                                <li>Ćwiczenia oddechowe</li>
                                <li>Funkcja Balans z relaksacyjną muzyką</li>
                                <li>Aktualizowana baza z ponad 6000 pysznych i prostych przepisów</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="fast2">
                <Container>
                    <h2 className="heading-2 text-center">Już ponad <span className="bg">2 500 000</span>osób uzyskało efekty z&nbsp;aplikacją Diet &amp; Training by Ann</h2>
                </Container>
            </section>
    
            <section className="buy">
                <Container>
                    <Row className="align-items-center">
                        <Col md="4">
                            <h3>PROMOCJA LIMITOWANA</h3>
                            <h2>-30%</h2>
                            <h4>Spróbuj diety i treningów w cenach, które się nie powtórzą</h4>
                        </Col>
    
                        <Col md="8">
                            <CardDeck className="multi-products">
                                <SaleProductProvider tag='landing:last-chance' source='fast' promoCode="dieta30">
                                    <SaleProducts color='white' showThumbnail={false} showShortDescription={false} />
                                </SaleProductProvider>
                            </CardDeck>
                        </Col>
                    </Row>        
                </Container>
            </section>
            
            <section className="fast11">
                <Container>
                    <h2>Koniec z nudnymi i powtarzalnymi ćwiczeniami! Podejmij wyzwanie!</h2>
                    <br/>
                    <h3>Skorzystaj z <span className="bg">ponad 70 różnych</span> planów treningowych<br/>na 3 poziomach:</h3>
    
                    <Row>
                        <Col md={{size: 10, offset: 1}}>
                            <Row>
                                <Col md="4">
                                    <div className="box violet">
                                        <img src={level1} alt="" />
                                        ŁATWY
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="box blue">
                                        <img src={level2} alt="" />
                                        ŚREDNI
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="box pink">
                                        <img src={level3} alt="" />
                                        TRUDNY
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="fast12">
                <Container>
                    <h2>Plany treningowe dopasowane do Twoich potrzeb</h2>
    
                    <div className="box">
                        <dl>
                            <dt>Dla początkujących</dt>
                            <dd>Start, Yoga for beginners, Full Body Miniband, 6 Pack ABS Easy, 14 Day Beginner Challange, Easy Best Workout for Man, Healthy Knees, Easy Summer Shape, Easy Tabata, Easy Pyramid, Healthy Mom, Total Body Workout, Couple Workout,</dd>
                        </dl>
                        <dl>
                            <dt>Bardzo szybkie spalanie</dt>
                            <dd>Whole Body Cardio, 6-Pack ABS, Karate Cardio Military, Summer Shape, Pyramid, Energizing Yoga,</dd>
                        </dl>

                        <div className="d-none d-md-block">
                        <dl>
                                <dt>Przyspieszone rzeźbienie sylwetki</dt>
                                <dd>6 Pack ABS, TX7, Summer Shape, Total Body Workout, Best Best Workout for Man, Tabata, Body &amp; Mind</dd>
                            </dl>
        
                            <dl>
                                <dt>Spalanie z wykorzystaniem gum</dt>
                                <dd>Full Body Miniband, Healthy Knees, Summer Shape</dd>
                            </dl>
        
                            <dl>
                                <dt>Yoga</dt>
                                <dd>Yoga for Beginners, Energizing Yoga, Relaxation &amp; Stress Relief Yoga</dd>
                            </dl>
        
                            <dl>
                                <dt>Wyzwanie</dt>
                                <dd>Challenge 30, 14 Day Beginner Challange, Challenge 7</dd>
                            </dl>
        
                            <dl>
                                <dt>Dla kobiet po ciąży</dt>
                                <dd>Healthy Mom (1st trimester), Healthy Mom (2st trimester) Start, Healthy Mom (2st trimester) Karate, Healthy Mom (2st trimester) Mini Band, Healthy Mom (3rd trimester) Mini Band, Healthy Mom (3rd trimester) Whole Body,</dd>
                            </dl>
        
                            <dl>
                                <dt>Zaawansowani i średnio zaawansowani</dt>
                                <dd>Hard TX7, Advanced Pyramid, Advanced Tabata, Hard Summer Shape, Hard Best Workout for Man,</dd>
                            </dl>
        
                            <dl>
                                <dt>Dla mężczyzn</dt>
                                <dd>Easy Best Workout for Man, Best Workout for Man, Hard Best Workout for man</dd>
                            </dl>
        
                            <dl>
                                <dt>W parach</dt>
                                <dd>Couple Workout</dd>
                            </dl>
        
                            <dl>
                                <dt>Profilaktyczne na problemy z plecami i kolanami</dt>
                                <dd>Strong Core, Healthy Knees</dd>
                            </dl>

                            <dl>
                                <dt>Treningi dla dojrzałych</dt>
                                <dd>Dojrzałe spalanie, Zawsze w formie, Mocne kości</dd>
                            </dl>
                        </div>
    
                        <div className="d-block d-md-none">
                            <Collapse isOpen={isOpen}>
                                <dl>
                                    <dt>Przyspieszone rzeźbienie sylwetki</dt>
                                    <dd>6 Pack ABS, TX7, Summer Shape, Total Body Workout, Best Best Workout for Man, Tabata, Body &amp; Mind</dd>
                                </dl>
            
                                <dl>
                                    <dt>Spalanie z wykorzystaniem gum</dt>
                                    <dd>Full Body Miniband, Healthy Knees, Summer Shape</dd>
                                </dl>
            
                                <dl>
                                    <dt>Yoga</dt>
                                    <dd>Yoga for Beginners, Energizing Yoga, Relaxation &amp; Stress Relief Yoga</dd>
                                </dl>
            
                                <dl>
                                    <dt>Wyzwanie</dt>
                                    <dd>Challenge 30, 14 Day Beginner Challange, Challenge 7</dd>
                                </dl>
            
                                <dl>
                                    <dt>Dla kobiet po ciąży</dt>
                                    <dd>Healthy Mom (1st trimester), Healthy Mom (2st trimester) Start, Healthy Mom (2st trimester) Karate, Healthy Mom (2st trimester) Mini Band, Healthy Mom (3rd trimester) Mini Band, Healthy Mom (3rd trimester) Whole Body,</dd>
                                </dl>
            
                                <dl>
                                    <dt>Zaawansowani i średnio zaawansowani</dt>
                                    <dd>Hard TX7, Advanced Pyramid, Advanced Tabata, Hard Summer Shape, Hard Best Workout for Man,</dd>
                                </dl>
            
                                <dl>
                                    <dt>Dla mężczyzn</dt>
                                    <dd>Easy Best Workout for Man, Best Workout for Man, Hard Best Workout for man</dd>
                                </dl>
            
                                <dl>
                                    <dt>W parach</dt>
                                    <dd>Couple Workout</dd>
                                </dl>
            
                                <dl>
                                    <dt>Profilaktyczne na problemy z plecami i kolanami</dt>
                                    <dd>Strong Core, Healthy Knees</dd>
                                </dl>

                                <dl>
                                    <dt>Treningi dla dojrzałych</dt>
                                    <dd>Dojrzałe spalanie, Zawsze w formie, Mocne kości</dd>
                                </dl>
                            </Collapse>
                            
                            <div className="text-center">
                                <Button color="blue" onClick={() => toggle()}>Pokaż wszystkie</Button>
                            </div>
                        </div>
                        
                    </div>
    
                    <h2 className="text-center mt-5 mb-0">Treningi już od <span className="bg">15 minut</span> dziennie!</h2>
                </Container>
            </section>
    
            <section className="fast6">
                <Container>
                    <Row className="align-items-center">
                        
                        <Col md={{size: 6, offset: 6}}>
                            <img src={appIcon} alt="" className='app-icon' />
                            <h2>Natychmiastowy dostęp do aplikacji</h2>
                            <p>Już kilka chwil po zakupie otrzymasz możliwość korzystania z Diet &amp; Training by Ann na swoim komputerze, telefonie i tablecie!</p>
                        </Col>
                    </Row>
    
                    <GatsbyImage image={data.hand.childImageSharp.gatsbyImageData} className="d-md-none" />
                </Container>
            </section>
    
            <section className="buy">
                <Container>
                    <Row className="align-items-center">
                        <Col md="4">
                            <h3>PROMOCJA LIMITOWANA</h3>
                            <h2>-30%</h2>
                            <h4>Spróbuj diety i treningów w cenach, które się nie powtórzą</h4>
                        </Col>
    
                        <Col md="8">
                            <CardDeck className="multi-products">
                                <SaleProductProvider tag='landing:last-chance' source='fast' promoCode="dieta30">
                                    <SaleProducts color='white' showThumbnail={false} showShortDescription={false} />
                                </SaleProductProvider>
                            </CardDeck>
                        </Col>
                    </Row>        
                </Container>
            </section>
    
            <section className="fast13">
                <Container>
                    <h2 className="text-center">Zyskaj maksymalną motywację do działania</h2>
                    <Row className="align-items-center">
                        <Col md="6">
                            <div className="video-box">
                                <div className="embed-responsive embed-responsive-1by1">
                                    <iframe
                                        className="embed-responsive-item"
                                        src="https://player.vimeo.com/video/409683956"
                                        title="video"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="box">
                                <p className="quote">"Masz treningi dostosowane na każdy dzień. Dietę tak samo, dieta jest bardzo fajnie rozpisana"</p>
                                <p className="sign">Martyna</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mariola13">
                <Container className="text-center">
                    <h2 className="mb-5">Trenerzy Diet &amp; Training by Ann</h2>
                    <Row>
                        <Col md="4">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.ania.childImageSharp.gatsbyImageData} />
                                <h4>Anna<br/>Lewandowska</h4>
                            </div>
                            
                            <p className="pink">Treningi funkcjonalne</p>
                            <p>Wielokrotna medalistka mistrzostw świata, Europy i Polski w karate tradycyjnym. Była reprezentantka Polski w karate. Dyplomowana trenerka fitness i TRX. Z wykształcenia manager sportowy i dietetyk.</p>
                        </Col>
                        <Col md="4" className="mt-5 mt-md-0">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.jj.childImageSharp.gatsbyImageData} />
                                <h4>Joanna<br/>Jędrzejczyk</h4>
                            </div>
                            <p className="pink">Treningi z elementami sztuk walki</p>
                            <p>Reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA). Trzykrotna zawodowa mistrzyni świata. Pierwsza Polka w prestiżowej organizacji Ultimate Fighting Championship.</p>
                        </Col>
                        <Col md="4" className="mt-5 mt-md-0">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.mariola.childImageSharp.gatsbyImageData} />
                                <h4>Mariola<br/>Bojarska-Ferenc</h4>
                            </div>
                            <p className="pink">Treningi dla dojrzałych</p>
                            <p>Absolwentka Akademii Wychowania Fizycznego w Warszawie. Była członkini kadry narodowej w gimnastyce artystycznej. Instruktorka fitness z wieloletnim doświadczeniem, członkini najważniejszych światowych organizacji fitness.</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md="4" className="mt-5">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} />
                                <h4>Ewelina<br/>Godlewska</h4>
                            </div>
                            <p className="pink">Yoga</p>
                            <p>Dyplomowana nauczycielka yogi z ponad 10-letnim doświadczeniem. Z wykształcenia psycholog i instruktor rekreacji ruchowej o specjalności ćwiczenia psychofizyczne w oparciu o system hatha yogi.</p>
                        </Col>
                        <Col md="4" className="mt-5">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.kuba.childImageSharp.gatsbyImageData} />
                                <h4>Jakub<br/>Głąb</h4>
                            </div>
                            <p className="pink">Treningi dla mężczyzn i par</p>
                            <p>Mistrz Polski w Fitness Sylwetkowym, wielokrotny Mistrz Polski, Europy i Świata w Karate Tradycyjnym. Z zawodu trener personalny, dietetyk, instruktor samoobrony i fitness, certyfikowany trener TRX.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="fast14">
                <Container>
                    <Row>
                        <Col md="6">
                            <h2>Ta sama aplikacja = 4 różne diety</h2>
                            <p>Każdy z nas jest inny. Mamy odmienne upodobania, przyzwy­czajenia i nietolerancje pokarmowe. Dlatego w aplikacji znajdziesz propozycje aż czterech różnych diet. Wybierz tę, która pasuje do Ciebie najlepiej! Proste przepisy pozwolą Ci na wyjątkowo szybkie przygotowanie zdrowych posiłków, dzięki czemu zaoszczędzisz czas, pieniądze i energię.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className="fast3">
                <Container>
                    <Row>
                        <Col xs="6" md="3">
                            <img src={icon1} alt="" />
                            <p>Wymiana posiłków i&nbsp;produktów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon2} alt="" />
                            <p>Ponad 4050 dni treningowych</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon3} alt="" />
                            <p>Opieka dietetyków online</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon4} alt="" />
                            <p>Jadłospisy okolicznościowe</p>
                        </Col>
                    </Row>
    
                    <Row className="mt-md-5">
                        <Col xs="6" md="3">
                            <img src={icon5} alt="" />
                            <p>Pomiary i śledzenie postępów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon6} alt="" />
                            <p>Rady, wskazówki, motywacja</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon7} alt="" />
                            <p>Możliwości ćwiczenia ze sprzętem lub bez</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={icon8} alt="" />
                            <p>Nagrania HD z&nbsp;ekspertami</p>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="fast15">
                <Container>
                    <h2 className="text-center">Już ponad <span className="bg">2 500 000</span> osób uzyskało efekty z&nbsp;aplikacją Diet &amp; Training by Ann</h2>
                    <h3 className="text-center">Sukcesy miesiąca! Zobacz jak tego dokonały!</h3>
    
                    <Slider {...settings}>
                        <div>
                            <div className="box">   
                                <Row className="align-items-center">
                                    <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                                        <p>Dopiero, kiedy sięgnęłam po aplikację Ani, zobaczyłam piorunujące efekty. Kiedyś było nie do pomyślenia żebym ćwiczyła. Dziś wiem, jakie to jest ważne, jeśli chce się mieć efekty i przede wszystkim jeśli chce się być zdrowym.</p>
                                        <p className="sign">Magda</p>
                                    </Col>
                                    <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                                        <img src={metamorphose1} className="img-fluid" alt="" />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div>
                            <div className="box">
                                <Row className="align-items-center">
                                    <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                                        <p>Najpierw bardzo nieśmiało spoglądałam na ćwiczenia i na wszystkie " insta przemiany" na profilu aż wreszcie weszłam w pierwszy #challange Ani #trenujezlewą i Apka mnie pochłonęła. Radość po każdym treningu była ogromna.</p>
                                        <p>Moja świadomość wyboru przy zakupach poszła do góry, gotuje z apką dla całej rodziny, dania są szybkie, łatwe i smaczne. Dzięki liście produktów nie marnuje już jedzenia.</p>
                                        <p className="sign">Aneta</p>
                                    </Col>
                                    <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                                        <img src={metamorphose2} className="img-fluid" alt="" />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div>
                            <div className="box">
                                <Row className="align-items-center">
                                    <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                                        <p>Jestem pewna, że z aplikacją i motywacją tak fantastycznego teamu można osiągnąć wszystko. Jeśli kto jeszcze ma wątpliwości to proszę nie zastanawiajcie się tylko zawalczcie o siebie tak jak zrobiłam to ja. Za każdego bardzo mocno trzymam kciuki i wspieram całym sercem</p>
                                        <p className="sign">Elwira</p>
                                    </Col>
                                    <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                                        <img src={metamorphose3} className="img-fluid" alt="" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Slider>
                   
                </Container>
            </section>
    
            <section className="buy" id="buy">
                <Container>
                    <h2>Nie strać tej szansy!</h2>
                    <Row className="align-items-center">
                        <Col md="4">
                            <h3>PROMOCJA LIMITOWANA</h3>
                            <h2>-30%</h2>
                            <h4>Spróbuj diety i treningów w cenach, które się nie powtórzą</h4>
                        </Col>
    
                        <Col md="8">
                            <CardDeck className="multi-products">
                                <SaleProductProvider tag='landing:last-chance' source='fast' promoCode="dieta30">
                                    <SaleProducts color='white' showThumbnail={false} showShortDescription={false} />
                                </SaleProductProvider>
                            </CardDeck>
                        </Col>
                    </Row>        
                </Container>
            </section>
        </Layout>
    );
}

export default Page;

export const query = graphql`{
  hero: file(relativePath: {eq: "company/backgroung_top.jpg"}) {
    ...fluidImage1920
  }
  app1: file(relativePath: {eq: "company/app1.png"}) {
    ...fluidImage960
  }
  app2: file(relativePath: {eq: "company/app2.png"}) {
    ...fluidImage960
  }
  headphones: file(relativePath: {eq: "company/headphones.png"}) {
    ...fluidImage960
  }
  hand: file(relativePath: {eq: "company/hand.jpg"}) {
    ...fluidImage960
  }
  ania: file(relativePath: {eq: "mariola/ania.jpg"}) {
    ...fluidImage960
  }
  jj: file(relativePath: {eq: "mariola/jj.jpg"}) {
    ...fluidImage960
  }
  mariola: file(relativePath: {eq: "mariola/mariola.jpg"}) {
    ...fluidImage960
  }
  kuba: file(relativePath: {eq: "mariola/kuba.jpg"}) {
    ...fluidImage960
  }
  ewelina: file(relativePath: {eq: "mariola/ewelina.jpg"}) {
    ...fluidImage960
  }
  ogImage: file(relativePath: {eq: "company/ogimage.png"}) {
    childImageSharp {
      gatsbyImageData(width: 1200, placeholder: BLURRED, layout: FIXED)
    }
  }
}
`;
